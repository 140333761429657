import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBookmark, faFileAudio
} from '@fortawesome/free-regular-svg-icons';
import { Row, Col, Navbar, Nav, Tab, Toast, Image, Modal } from 'react-bootstrap';

// import { useTour } from './context/tour-context';
import KuulaProject from './components/KuulaProject';
// import SiteItem from './components/SiteItem';

import './styles/App.css';
import './styles/TabStyles.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

const site_items = [
	{ id: 1,  hotspot_name: "STP001", img_loc: "0000_Layer-24.png", 	"title": "", "description": "", "visible": false },
	{ id: 2,  hotspot_name: "STP002", img_loc: "0001_mic.png", 			"title": "", "description": "", "visible": false },
	{ id: 3,  hotspot_name: "STP003", img_loc: "0002_Butterfly-Net.F03.png", "title": "", "description": "", "visible": false },
	{ id: 4,  hotspot_name: "STP004", img_loc: "0003_hat.png", 			"title": "", "description": "", "visible": false },
	{ id: 5,  hotspot_name: "STP005", img_loc: "0004_Layer-13.png", 	"title": "", "description": "", "visible": false },
	{ id: 6,  hotspot_name: "STP006", img_loc: "0005_Layer-11.png", 	"title": "", "description": "", "visible": false },
	{ id: 7,  hotspot_name: "STP007", img_loc: "0006_Layer-17.png", 	"title": "", "description": "", "visible": false },
	{ id: 8,  hotspot_name: "STP008", img_loc: "0007_Layer-7.png", 		"title": "", "description": "", "visible": false },
	{ id: 9,  hotspot_name: "STP009", img_loc: "0008_Layer-5.png", 		"title": "", "description": "", "visible": false },
	{ id: 10, hotspot_name: "STP010", img_loc: "0009_JarofFlies.png", 	"title": "", "description": "", "visible": false },
	{ id: 11, hotspot_name: "STP011", img_loc: "0010_guitar2.png", 		"title": "", "description": "", "visible": false },
	{ id: 12, hotspot_name: "STP012", img_loc: "0011_cards.png", 		"title": "", "description": "", "visible": false },
	{ id: 13, hotspot_name: "STP013", img_loc: "0012_bass.png", 		"title": "", "description": "", "visible": false }
]

export default function App() {

	// const { tour } = useTour();
	const [ show, setShow ] = useState(true);
	const [ firstLoad, setFirstLoad ] = useState(true);

	// const [ toastShow, setToastShow ] = useState(false);
	const [ toastList, setToastList ] = useState([]);

	const [ offcanvas, setOffcanvas ] = useState('');
	const [ tabSelected, setTab ] = useState('items');

	const [ items, setItems ] = useState([ ...site_items ]);
	const [ itemCount, setItemCount ] = useState(0);

	const [ hotspot, setSelectedHotspot ] = useState('');

	function toggleCanvas() {
		if ( offcanvas === '' )
			setOffcanvas('show')
		else 
			setOffcanvas('')
	}

	useEffect(() => {
		
		if ( localStorage.getItem('eggs') ) {
			let count = 0;
			var localEggs = JSON.parse(localStorage.getItem('eggs'));
			var updated_items = localEggs?.map(item => {
				if (item.visible) count++;
				return item;
			})
			setItemCount(count);
			setItems([ ...updated_items ])
		}

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			setSelectedHotspot(e.data.name);
		});

	}, []);

	useEffect(() => {

		if ( hotspot !== '' ) {
			// check if item clicked on
			let new_count = itemCount;
			
			var new_toast = {}
			var new_items = items.map(item => {
				if ( item.hotspot_name === hotspot && !item.visible ) {
					new_count++;
					// add item to toast list
					new_toast = {
						'id': item.id,
						'message': 'Hidden Item Found!',
						'item_name': item.title,
						'img': item.img_loc
					}
					return { ...item, visible: true }
				}
				return { ...item };  
			});

			if ( new_count > itemCount ) {

				setItemCount(new_count);
				setItems([ ...new_items ]);
				localStorage.setItem('eggs', JSON.stringify(new_items))
				setToastList([
					...toastList,
					new_toast
				])

			}
		}

	}, [hotspot])

	function clearFoundItems() {
		localStorage.removeItem('eggs');
		localStorage.setItem('eggs', JSON.stringify([ ...site_items ]));
		setItems([ ...site_items ]);
		setItemCount(0);
		setSelectedHotspot('')
	}

	function removeToastFromList(toast_id) {
		var removeItemIndex = toastList.findIndex(toast => toast.id === toast_id);
		var newToastList = [ ...toastList ];
		newToastList.splice(removeItemIndex, 1);
		setToastList(newToastList);
	}

	function handleClose() {
		setShow(false);
		if ( firstLoad ) {
			setFirstLoad(false);
		}
	}

	function showInstructions(e) {
		e.preventDefault();
		setShow(true);
	}

	useEffect(() => {
		
		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

	}, []);

	return (
		<div className="App">
			
			<Modal show={show} onHide={handleClose}>
				<Modal.Body>
					<img src={`images/intro_instr.png`} alt="Instructions" style={{ width: '100%' }} />
				</Modal.Body>
				<Modal.Footer style={{ textAlign: 'center' }}>
					<button type="button" className="btn" style={{ backgroundColor: '#000000', fontWeight: 'bold', margin: '0 auto', color: '#ffffff', fontSize: '1.5em', border: 'solid 1px #000000' }} onClick={handleClose}>
						{firstLoad ? 'Enter!' : 'Close'}
					</button>
				</Modal.Footer>
			</Modal>

			<Navbar variant="dark" bg="transparent" fixed="bottom" expand={false} defaultExpanded={false}>
				<Navbar.Toggle className="p-0 border-0" data-toggle="offcanvas" onClick={toggleCanvas} />
				<Navbar.Collapse id="offcanvas_navigation">
					<Tab.Container activeKey={tabSelected} onSelect={(k) => setTab(k)}>
						<Row id="tour-title-row">
							<Col className="text-center">
								<img src={`images/big_logo.png`} alt="Stone Temple Pilots" style={{ width: '150px' }} className="rounded" />
							</Col>
						</Row>
						<Row id="tab-row">
							<Col>
								<Nav justify variant="tabs" defaultActiveKey="rooms" style={{ flexDirection: 'row', maxWidth: '400px' }}>
									<Nav.Item>
										<Nav.Link eventKey="items">
											<FontAwesomeIcon icon={faBookmark} /> <span id="items_found">{itemCount}</span>/<span id="items_total">{items.length}</span>
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="audio">
											<FontAwesomeIcon icon={faFileAudio} />
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Col>
						</Row>
						<Row style={{ margin: 0 }}>
							<Col>
								<Tab.Content>
									<Tab.Pane transition={false} eventKey="audio">
										<iframe title="stp-spotify-player"
											style={{ borderRadius: '12px', marginTop: '20px' }} src="https://open.spotify.com/embed/album/57lcTrUlYgfMIPvBUsVU6h?utm_source=generator" 
											width="100%" height="600px" frameborder="0" allowFullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
										<div style={{ fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100' }}>
											<a href="https://open.spotify.com/album/57lcTrUlYgfMIPvBUsVU6h?si=21AG8SFLSEOVpaHqly1I7Q" title="Purple (2019 Remaster)" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>Purple (2019 Remaster)</a>
										</div>
									</Tab.Pane>
									<Tab.Pane transition={false} eventKey="items">
										{items.length && (
											<div className="tab-pane-instr">
												Can you find all the Hidden Items?<br /> Clicking the items in the 360 viewer will reveal them here.
												{itemCount > 0 && (
													<div>
														[ <span style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }} onClick={() => { clearFoundItems() }}>Clear Found Items</span> ]
													</div>
												)}
											</div>
										)}
										<div id="items">
											<Row>
												{items?.map((item, idx) => 
													item.visible ? (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/items/${item.img_loc}`} alt="found item" className="item_thumb" title={item.title} fluid />
														</Col>
													) : (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/hidden-item.png`} alt="hidden item" className="item_thumb" title={'Hidden Item'} fluid />
														</Col>
													)
												)}
											</Row>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Navbar.Collapse>
			</Navbar>

			<div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000' }}>
				{toastList?.map(toast => {
					return (
						<Toast key={toast.id} onClose={() => removeToastFromList(toast.id)} show={true} delay={7000} autohide>
							<Toast.Header>
								<FontAwesomeIcon icon={faBookmark} className="mr-3" />
								<strong className="mr-auto">{toast.message}</strong>
							</Toast.Header>
							<Toast.Body style={{ textAlign: 'left' }}>
								<img src={'images/items/' +toast.img} className="rounded mr-2" style={{ height: '50px', width: '50px' }} alt="" />
								{toast.item_name}
							</Toast.Body>
						</Toast>
					)
				})}
			</div>

			<KuulaProject />
		</div>
	)
}
 